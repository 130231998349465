import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pipe'
})
export class CommonPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}

@Pipe({
  name: 'replaceLineBreak'
})
export class ReplaceLineBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return ''; }
    // Replace ** with bold tags
    value = value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    return value.replace(/\n/g, '<br>');
  }
}
