<div class="row">
  <div class="col-sm-12">
    <form [formGroup]="fg" (ngSubmit)="onSubmit()" novalidate>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
        <div class="float-right">
          <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Cross click')">X
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div *ngIf="!bodyText">
          Do you really want to proceed?
          <div class="form-group row" *ngIf="skipTextBoxInputQuiz!==true">
            <label for="confirmTextBox" class="col-form-label col-sm-6">Type
              <strong>{{inputConfirmText}}</strong></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="confirmTextBox" formControlName="confirmTextBox">
            </div>
          </div>
        </div>

        <div *ngIf="bodyText">
          {{bodyText}}
        </div>

        <div>
          <p *ngIf="errorMsgHtml" class="text-danger">{{errorMsgHtml}}</p>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>&nbsp;&nbsp;
        <button type="submit" class="btn btn-primary float-right">Proceed</button>
      </div>
    </form>
  </div>
</div>
