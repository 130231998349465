import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {NgxCaptureService} from "ngx-capture";
import {faThumbsUp, faThumbsDown} from '@fortawesome/free-solid-svg-icons';
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AppsettingsService} from "../../services/appsettings.service";

@Component({
  selector: 'app-sematic-search',
  templateUrl: './sematic-search.component.html',
  styleUrls: ['./sematic-search.component.less']
})
export class SematicSearchComponent implements OnInit {

  public readonly maxCharsToShow = 1200;
  q: string;
  lastSearchQ;
  searchResults: any [];
  gptSummaryForInputKeywords;
  gptSearchKeywordsForInputKeywords;

  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;

  constructor(private appDataFetchService: AppDataFetchService,
              private  activatedRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              public appSettings: AppsettingsService,
              public sanitizer: DomSanitizer,
              private readonly captureService: NgxCaptureService) {
  }

  ngOnInit(): void {
  }

  trimToNearestWord(content: string, maxLength: number): string {
    if (content.length <= maxLength) {
      return content;
    }

    // Find the index of the last space character within the first maxLength characters
    const lastSpaceIndex = content.lastIndexOf(' ', maxLength);

    // If no space is found, trim at maxLength
    const trimIndex = lastSpaceIndex !== -1 ? lastSpaceIndex : maxLength;

    return content.slice(0, trimIndex);
  }

  search(): void {
    const searchTerm = this.q?.trim();
    if (searchTerm) {
      this.lastSearchQ = searchTerm;
      this.appDataFetchService.semanticSearch({q: searchTerm}).subscribe((r) => {
        this.searchResults = r.searchResults;
        this.gptSummaryForInputKeywords = r.gptSummaryForInputKeywords;
        this.gptSearchKeywordsForInputKeywords = r.gptSearchKeywordsForInputKeywords;
      }, (err) => {
        this.searchResults = [];
        this.gptSummaryForInputKeywords = '';
        this.gptSearchKeywordsForInputKeywords = '';
      });
    }
  }

  sendFeedback(prams: any, idx: number): void {
    const body = {
      q: this.q,
      id: prams.id,
      rank: idx + 1,
      thumpDown: prams.thumpDown,
      thumpUp: prams.thumpUp,
      comment: prams.feedback
    };
    if (!body.thumpUp && !body.thumpDown) {
      body.comment = null;
    }
    if (body.comment) {
      body.comment = body.comment.substring(0, 1023);
    }
    if (body.q) {
      body.q = body.q.toLowerCase().trim();
      body.q = body.q.substring(0, 2047);
    }

    this.appDataFetchService.semanticSearchFeedBack(body).subscribe(() => {

    }, () => {

    });
  }
}
