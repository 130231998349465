<div class="card-main-wrapper  mb-5 mt-5 container">
  <div>
    <h3>BVR Search: </h3>
    <p>
      <input type="text" [(ngModel)]="q" (keydown.enter)="search()"
             placeholder="Search..."
             class="form-control"
             aria-label="Type something... "
             style=" width: 75%;height: 40px">
      <button class="btn btn-outline-secondary ml-4" (click)="search()" style="height: 40px">Search</button>
    </p>
  </div>

  <div *ngIf="searchResults">
    <div class="message mb-5">
      <p class="text-sm-left">
        {{gptSummaryForInputKeywords}}
      </p>
      <p class="font-italic">Keywords: {{gptSearchKeywordsForInputKeywords}}</p>
    </div>
    <div>
      Results:
      <div *ngFor="let result of searchResults; let idx = index" class="message mb-5">
        <div style="background-color: white" class="p-5" *ngIf="result">
          <div class="form-inline">
            <h3 class="font-weight-bolder">{{result.title}}</h3>
            <button style="margin-left: auto"
                    class="btn btn-xs btn-outline-secondary float-right"
                    *ngIf="result.content.length > maxCharsToShow"
                    (click)="result.isExpanded=!result.isExpanded">
              {{ !result.isExpanded ? 'Show More' : 'Show Less' }}
            </button>
          </div>

          <div>
            <p *ngIf="result.content.length <= maxCharsToShow">{{ result.content }}</p>
            <p
              *ngIf="result.content.length > maxCharsToShow && !result.isExpanded">{{ trimToNearestWord(result.content, maxCharsToShow) }}</p>
            <p *ngIf="result.content.length > maxCharsToShow && result.isExpanded">{{ result.content }}</p>
            <button class="btn btn-xs btn-outline-secondary float-right"
                    *ngIf="result.content.length > maxCharsToShow" (click)="result.isExpanded=!result.isExpanded">
              {{ !result.isExpanded ? 'Show More' : 'Show Less' }}
            </button>
          </div>

          <p *ngIf="result.formattedLink"><a href="{{result.formattedLink}}" target="_blank">Read</a></p>
          <p>Published Date: {{result.formattedDate}}</p>
          <p>Author: {{result.author}} | Publishers: {{result.publishers}}</p>

          <p>Score: {{result.score}}</p>

          <div>
            <fa-icon [icon]="faThumbsDown"
                     (click)="result.thumpDown=!result.thumpDown;result.thumpUp=false;sendFeedback(result, idx)"
                     class="mr-4 cursor-pointer" [ngClass]="{'text-danger': result.thumpDown}"></fa-icon>
            <fa-icon [icon]="faThumbsUp"
                     (click)="result.thumpUp=!result.thumpUp;result.thumpDown=false;sendFeedback(result, idx)"
                     class="mr-4 cursor-pointer" [ngClass]="{'text-success': result.thumpUp}"></fa-icon>
            <input style="width: 25%" type="text" class="form-control mr-4" placeholder="(Optional) Type Feedback"
                   *ngIf="result.thumpUp || result.thumpDown" [(ngModel)]="result.feedback">
            <button *ngIf="result.thumpUp || result.thumpDown" class="btn btn-xs btn-outline-secondary"
                    [disabled]="!(result && result.feedback)"
                    (click)="sendFeedback(result,idx);result.feedbackSentOnce=true"
                    [ngClass]="{'text-success': result.feedbackSentOnce}">{{result.feedbackSentOnce ? "Sent" : "Send" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
