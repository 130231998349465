<div class="row">
  <div class="col-sm-12">
    <form [formGroup]="fg" (ngSubmit)="onSubmit()" novalidate>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Rename</h4>
        <div class="float-right">
          <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Cross click')">X
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div *ngIf="bodyText">
          {{bodyText}}
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <input type="text" class="form-control" id="newName" formControlName="newName">
          </div>
        </div>

        <div>
          <p *ngIf="errorMsgHtml" class="text-danger">{{errorMsgHtml}}</p>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>&nbsp;&nbsp;
        <button type="submit" class="btn btn-primary float-right">Proceed</button>
      </div>
    </form>
  </div>
</div>
