import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {NgxFileDropModule} from 'ngx-file-drop';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {CommonModule, DatePipe} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgbDropdownConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DecimalPipe} from '@angular/common';
import {DndModule} from 'ngx-drag-drop';
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {NgxCaptureModule} from 'ngx-capture';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppsettingsService} from './services/appsettings.service';
import {ErrorInterceptor} from './services/auth-interceptor/error.interceptor';
import {RequestInterceptor} from './services/auth-interceptor/request.interceptor';
import {AppDataFetchService} from "./services/app-data-fetch.service";
import {MainComponent} from './components/main/main.component';
import {RenameDlgComponent} from './components/common/rename-dlg/rename-dlg.component';
import {DeleteDlgComponent} from './components/common/delete-dlg/delete-dlg.component';
import {SematicSearchComponent} from './components/sematic-search/sematic-search.component';
import {CommonPipe, ReplaceLineBreakPipe} from './services/common.pipe';
import {LandingComponent} from './components/landing/landing.component';
import {UsagesComponent} from './components/usages/usages.component';
import {ButtonViewComponent} from './components/common/st-buttonview/button-view.component';


export function initConfig(appConfigService: AppsettingsService): any {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    RenameDlgComponent,
    DeleteDlgComponent,
    SematicSearchComponent,
    CommonPipe,
    ReplaceLineBreakPipe,
    LandingComponent,
    UsagesComponent,
    ButtonViewComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    BrowserModule,
    HttpClientModule,
    NgScrollbarModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    DpDatePickerModule,
    NgxSliderModule,
    FontAwesomeModule,
    Ng2SmartTableModule,
    NgxFileDropModule,
    DndModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    PdfViewerModule,
    NgxCaptureModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppsettingsService],
      multi: true
    },
    NgbDropdownConfig,
    AppDataFetchService,
    DatePipe,
    DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(config: NgbDropdownConfig) {
    // customize default values of dropdowns used by this component tree
    config.placement = 'bottom-left';
    config.autoClose = true;
    config.container = 'body';
  }
}
