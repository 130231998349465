import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MainComponent} from "./components/main/main.component";
import {SematicSearchComponent} from "./components/sematic-search/sematic-search.component";
import {LandingComponent} from "./components/landing/landing.component";
import {UsagesComponent} from "./components/usages/usages.component";

const routes: Routes = [
  {path: '', redirectTo: 'main', pathMatch: 'full'},
  {path: 'main', component: MainComponent},
  {path: 'search', component: SematicSearchComponent},
  {path: 'landing', component: LandingComponent},
  {path: 'usages', component: UsagesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
