import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {

  }

  private isRedirectionInitiated = false;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const self = this;
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        if (self.isRedirectionInitiated === false) {
          self.isRedirectionInitiated = true;
          const unAuthorisedReason = err?.error?.Reason;
          // @ts-ignore
          jsRedirectToLoginPage(unAuthorisedReason); // windows function angular_app.html page
        }
      }

      let error = err.error || err.error.message || err.statusText;
      if (error.originalError) {
        // Formatting db error from the frontend itself, will generalise this a bit later.
        error = error.originalError.info.message;
      }
      return throwError(error);
    }));
  }
}
