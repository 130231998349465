import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {ViewCell} from 'ng2-smart-table';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'button-view',
  template: `
    <div class="st-custom-button-view d-flex">
      <button *ngIf="buttonUi.showPDF===true" class="btn {{buttonUi.rowActionBtnClass}}" (click)="onOpenPDFClick()">
        <fa-icon [icon]="faPDF"></fa-icon>
      </button>&nbsp;
      <span  *ngIf="buttonUi.showInnerHTML===true"  (click)="onOpenPDFClick()" [innerHTML]="value">
      </span>
    </div>
  `,
})

export class ButtonViewComponent implements ViewCell, OnInit {
  faPDF = faFile;
  buttonUi: any;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() openPDF: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  onOpenPDFClick(): void {
    this.openPDF.emit(this.rowData);
  }
}
