// @ts-ignore
import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';

import {AppsettingsService} from './services/appsettings.service';
import {ProgressLoadingServiceService} from './services/progress-loading-service.service';
import {AppDataFetchService} from "./services/app-data-fetch.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-root]',
  templateUrl: 'app.component.html',
  styleUrls: ['./styles/global.scss'],
})

export class AppComponent implements OnInit {
  title = 'PDF';
  config: any;
  logOutURL: string;
  loggedInUserInfo: any;
  loading = false;

  constructor(public progressLoadingService: ProgressLoadingServiceService,
              private apiDataFetchService: AppDataFetchService,
              private configService: AppsettingsService) {
    this.config = this.configService.configSettings;
  }

  ngOnInit(): void {
    this.loggedInUserInfo = {};
    this.currentLoggedInUser();
    this.progressLoadingService
      .onLoadingChanged
      .subscribe(isLoading => setTimeout(() => this.loading = isLoading, 0));

  }

  currentLoggedInUser(): void {
    this.loggedInUserInfo = this.config.SESSIONS.AUTH_USER;
    console.log(this.loggedInUserInfo);
  }

}
