import {Injectable, EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressLoadingServiceService {
  private requests = 0;

  constructor() {
  }

  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  onStarted(): void {
    this.requests++;
    this.notify();
  }

  onFinished(): void {
    this.requests--;
    this.notify();
  }

  private notify(): void {
    this.onLoadingChanged.emit(this.requests !== 0);
  }
}
