import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ProgressLoadingServiceService} from '../progress-loading-service.service';
import {finalize} from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private progressLoadingService: ProgressLoadingServiceService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressLoadingService.onStarted();

    request = request.clone({
      withCredentials: true
    });
    // loader
    return next.handle(request).pipe(
      finalize(() => {
        this.progressLoadingService.onFinished();
      })
    );
  }
}
