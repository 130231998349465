import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as _ from 'lodash';
import * as uuid from 'uuid';

import {
  faClipboard,
  faPaperPlane,
  faArrowDown,
  faArrowRight,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faPlus,
  faMinus,
  faFolderPlus,
  faFolder,
  faStar,
  faCommentDots,
  faEllipsisH,
  faTrash,
  faEdit,
  faUndo,
  faObjectGroup,
  faSearch,
  faFilePdf,
  faWindowClose,
  faDownload,
  faCheck,
  faArrowAltCircleLeft,
  faArrowAltCircleRight
} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from 'moment';
import {NgxCaptureService} from 'ngx-capture';

import {AppsettingsService} from "../../services/appsettings.service";
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {DropEffect} from "ngx-drag-drop";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DeleteDlgComponent} from "../common/delete-dlg/delete-dlg.component";
import {RenameDlgComponent} from "../common/rename-dlg/rename-dlg.component";
import {tap} from "rxjs/operators";
import {PdfViewerComponent} from "ng2-pdf-viewer";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {
  public faArrowLeft = faArrowAltCircleLeft;
  public faArrowRightCircle = faArrowAltCircleRight;
  public faArrowUp = faPaperPlane;
  public faArrowDown = faArrowDown;
  public faArrowRight = faArrowRight;
  public faPlus = faPlus;
  public faMinus = faMinus;
  public faStar = faStar;
  public faFolder = faFolderPlus;
  public faFolderPlane = faFolder;
  public faComment = faCommentDots;
  public faDotLine = faEllipsisH;
  public faTrash = faTrash;
  public faEdit = faEdit;
  public faUndo = faUndo;
  public faSnipping = faObjectGroup;
  public faSearch = faSearch;
  public faFilePdf = faFilePdf;
  public faCross = faWindowClose;
  public faArrowAltCircleLeft = faArrowAltCircleUp;
  public faArrowAltCircleRight = faArrowAltCircleDown;
  public faDownload = faDownload;
  public faClipboardActiveIcon = faClipboard;
  public faCheck = faCheck;

  public list: any = [];
  public currentActiveSelectedFile;

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  @ViewChild('scrollMessageContainer') private myScrollMessageContainer: ElementRef;
  @ViewChild('screen', {static: true}) screen: any;
  pdfSrc: any;
  pdfSrcErr: any;
  currentPDFPageNumber = 1;
  currentPDFRotation = 0;
  currentPDFZoom = 1.0;
  currentPDFSearchStr: any;
  isSnippingModeActive = false;
  snippedImg;
  pdfFullyRendered;
  noFileAvailableToLoad = false;
  vendorDoc;
  allowCustomUpload;
  chatErr;
  isLeftNavClosed = false;

  messagesHistory: any[] = [];
  faqArr: any[] = [];
  isChatLoaded = false;
  inputTextMessage = '';

  constructor(private appDataFetchService: AppDataFetchService,
              private  activatedRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              public appSettings: AppsettingsService,
              public sanitizer: DomSanitizer,
              private readonly captureService: NgxCaptureService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.vendor_doc_id) {
        this.vendorDoc = {};
        this.vendorDoc.s3Key = queryParams.vendor_doc_id;
        this.vendorDoc.chatPDFSourceId = queryParams.chat_id;
        this.vendorDoc.folderId = 'default';
        this.currentActiveSelectedFile = this.vendorDoc;
        this.loadPDFAndChat();
      } else if (queryParams.custom_upload != null) {
        this.allowCustomUpload = true;
        this.refreshFilesList().then(() => {
          if (!(this.list.length)) {
            this.noFileAvailableToLoad = true;
          } else {
            for (const li of this.list) {
              if (li.files && li.files.length > 0) {
                this.onCurrentActiveSelectedFile(li.files[0]);
                break; // Break out of the loop after performing the action
              }
            }
          }
        });
      } else {
        this.noFileAvailableToLoad = true;
      }
    });
  }

  public refreshFilesList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appDataFetchService.filesList(null).subscribe((result) => {
        this.list = result.folders;
        resolve();
      });
    });
  }

  dndOnDnbDragCb(item: any, list: any[], effect: DropEffect): any {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  dndOnDropped(event: any, list: any): any {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
      this.updateFilePositionInList(event.data);
    }
  }

  updateFilePositionInList(fileO: any): void {
    console.log('db: ', fileO);
  }

  renameFile(fileO: any): void {
    this.renameFileOrFolder(fileO.id, fileO.name, 'file');
  }

  renameFolder(folderObj: any): void {
    this.renameFileOrFolder(folderObj.id, folderObj.name, 'folder');
  }

  deleteFile(fileO: any): void {
    this.deleteFileOrFolder(fileO.id, 'file');
  }

  deleteFolder(folderObj: any): void {
    this.deleteFileOrFolder(folderObj.id, 'folder');
  }


  private deleteFileOrFolder(id: any, resourceType: any): void {
    const modalRef = this.modalService.open(DeleteDlgComponent, {size: 'md'});
    modalRef.componentInstance.inputConfirmText = 'Delete';
    modalRef.componentInstance.proceedStatus.subscribe((receivedEntry) => {
      this.appDataFetchService.deleteFileFolder({id, resourceType}).subscribe(data => {
        if (data) {
          this.refreshFilesList();
          modalRef.close();
        } else {
          modalRef.componentInstance.errorMsgHtml = "Unable to delete";
        }
      }, error => {
        modalRef.componentInstance.errorMsgHtml = JSON.stringify(error);
      });
    });
  }

  private renameFileOrFolder(id: any, currentName: string, resourceType: any): void {
    const modalRef = this.modalService.open(RenameDlgComponent, {size: 'md'});
    modalRef.componentInstance.bodyText = 'Rename ' + resourceType + ' from ' + currentName + ' to: ';
    modalRef.componentInstance.proceedStatus.subscribe((newName) => {
      this.appDataFetchService.renameFileFolder({id, resourceType, newName}).subscribe(data => {
        if (data) {
          this.refreshFilesList();
          modalRef.close();
        } else {
          modalRef.componentInstance.errorMsgHtml = "Unable to rename";
        }
      }, error => {
        modalRef.componentInstance.errorMsgHtml = JSON.stringify(error);
      });
    });
  }

  getNextFolderNumber(folders: string[]): number {
    let maxNumber = 0;
    for (const folder of folders) {
      const match = folder.match(/\((\d+)\)$/);
      if (match) {
        const numb = parseInt(match[1], 10);
        if (!isNaN(numb) && numb > maxNumber) {
          maxNumber = numb;
        }
      }
    }
    return maxNumber + 1;
  }

  createNewFolder(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appDataFetchService.createNewFolder({name: params.name}).subscribe((newFolderCreated) => {
        resolve(newFolderCreated);
      }, (error) => {
        reject(error);
      });
    });
  }

  onCreateNewFolderBtnClicked(): void {
    this.appDataFetchService.filesList(null).subscribe((result) => {
      const folderNames = _.map(result.folders, (o) => o.name);
      const nextNewFolderRadii = this.getNextFolderNumber(folderNames);
      this.createNewFolder({name: `New Folder(${nextNewFolderRadii})`}).then((newFolderCreatedResult) => {
        this.refreshFilesList();
      });
    });
  }

  public uploadNewFileBtnPressed(files: NgxFileDropEntry[]): void {
    const newFiles: NgxFileDropEntry[] = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.onBeforeFileUpload(file);
        });
      }
    }
  }

  onBeforeFileUpload(file: any): void {
    this.appDataFetchService.filesList(null).subscribe((result) => {
      if (result && result.folders && result.folders.length && result.folders[0] && result.folders[0].id) {
        this.uploadNewFileToS3(file, result.folders[0]);
      } else {
        const folderNames = _.map(result.folders, (o) => o.name);
        const nextNewFolderRadii = this.getNextFolderNumber(folderNames);
        this.createNewFolder({name: `New Folder(${nextNewFolderRadii})`}).then((newFolderCreatedResult) => {
          this.uploadNewFileToS3(file, newFolderCreatedResult);
        });
      }
    });
  }

  uploadNewFileToS3(file: any, toFolder: any): void {
    console.log("uploadNewFileToS3");
    this.appDataFetchService.fileUpload(file, {
      folderId: toFolder.id,
      name: file.name
    }).subscribe((uploadedResult) => {
      const resultS3Key = uploadedResult.s3Key;
      this.refreshFilesList().then(r => {
        if (!(this.list.length)) {
          this.noFileAvailableToLoad = true;
        } else {
          this.noFileAvailableToLoad = false;
          for (const li of this.list) {
            if (li.files && li.files.length > 0) {
              const found = _.find(li.files, {s3Key: resultS3Key});
              if (found) {
                this.onCurrentActiveSelectedFile(li.files[0]);
                break; // Break out of the loop after performing the action
              }
            }
          }
        }
      });
    }, (error) => {
      console.error(error);
    });
  }


  onCurrentActiveSelectedFile(item: any): any {
    this.currentActiveSelectedFile = item;
    this.loadPDFAndChat();
  }

  public loadPDFAndChat(): any {
    this.messagesHistory = null;
    this.pdfSrcErr = null;
    this.appDataFetchService.loadS3PDF(this.currentActiveSelectedFile).subscribe((result) => {
      if (result && result.url) {
        this.pdfSrc = result.url;
        if (result.chatPDFSourceId) {
          this.currentActiveSelectedFile.chatPDFSourceId = result.chatPDFSourceId;
        }
        this.currentActiveSelectedFile.name = result.name;
      }
      this.pdfFullyRendered = false;
    }, (error) => {
      this.pdfSrcErr = true;
    });
  }

  onPDFFullyRenderedAtPDFViewer($ev): any {
    this.pdfFullyRendered = true;
    this.loadChatPDF();
  }

  onPDFViewerError($ev: any): void {
    // this.pdfSrcErr = $ev.name;
    console.log(this.pdfSrcErr);
  }

  sendMessage(): void {
    // Check if chat message is empty
    if (this.inputTextMessage.trim() === '') {
      return;
    }
    this.scrollToBottom();

    // Construct the new user query
    const query = {
      query: {
        role: 'user',
        content: this.inputTextMessage,
        attachment_image: this.snippedImg,
        mid: uuid.v4()
      }
    };
    this.messagesHistory.push(query);
    this.snippedImg = null;

    // Construct contextual queries in a single loop
    const contextualQuery = [];
    this.messagesHistory.forEach((o) => {
      if (o.query && o.query.content) {
        contextualQuery.push({
          role: 'user',
          content: o.query.content,
          attachment_image: o.query.attachment_image,
          uuid: o.query.uuid
        });
      }
      if (o.answer && o.answer.content) {
        contextualQuery.push({role: 'assistant', content: o.answer.content});
      }
    });

    // Get the last six contextual queries
    const lastSixContextualQuery = contextualQuery.slice(-6);

    // Call chatWithPDF method
    this.appDataFetchService.chatWithPDF({
      chatPDFSourceId: this.currentActiveSelectedFile.chatPDFSourceId,
      singleQuery: query,
      contextualQuery: lastSixContextualQuery
    }).subscribe((result) => {
      if (result.error) {
        this.chatErr = result.error;
      } else {
        // Update the last message with bot's response
        this.messagesHistory[this.messagesHistory.length - 1].answer = {
          role: 'assistant', content: result.content, references: result.references
        };
        this.scrollToBottom();
      }
    }, (err) => {
      console.error(err);
      this.chatErr = err.error || err;

    });

    // Clear the input field
    this.inputTextMessage = '';
  }

  loadChatPDF(): void {
    this.appDataFetchService.loadChatPDF({
      chatPDFSourceId: this.currentActiveSelectedFile.chatPDFSourceId
    }).subscribe((prevChats) => {
      this.isChatLoaded = true;
      this.faqArr = null;
      this.messagesHistory = prevChats.chat || [];
      if (prevChats.faq) {
        this.faqArr = prevChats.faq;
      }
      this.scrollToBottom();

    }, (err) => {
      this.isChatLoaded = true;
    });
  }

  copyAllChatToClipBoard(): void {
    this.faClipboardActiveIcon = faCheck;
    // @ts-ignore
    const val = document.getElementsByClassName('messages-container')[0].innerText;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onFaqQuestionClick(faqSelected: string): void {
    this.inputTextMessage = faqSelected;
    this.sendMessage();
    setTimeout(() => {
      this.faqArr = null;
    }, 1);
  }

  resetCopyAllChatToClipBoardIcon(): void {
    setTimeout(() => {
      if (this.faClipboardActiveIcon === faCheck) {
        this.faClipboardActiveIcon = faClipboard;
      }
    }, 2000);

  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollMessageContainer.nativeElement.scrollTop = this.myScrollMessageContainer.nativeElement.scrollHeight;
      } catch (err) {
      }
    }, 200);
  }

  removeReferencePageLinkPlaceholders(content: string): string {
    return content;
  }

  goToPDFPageNumber(pgNo: number): void {
    this.currentPDFPageNumber = pgNo;
  }


  deleteOneMessage(chatPDFSourceId: string, messageUUID: string): void {
    this.appDataFetchService.deleteOneMessage({
      chatPDFSourceId,
      mid: messageUUID
    }).subscribe((result) => {
      this.loadChatPDF();
    });
  }

  // Tool bar
  toggleSnippingModeActive(): void {
    window.scrollTo({top: 0});
    this.isSnippingModeActive = !this.isSnippingModeActive;
  }

  saveSnippedImage(img: string): void {
    this.snippedImg = img;
    this.isSnippingModeActive = false;
  }

  rotatePDF(): void {
    this.currentPDFRotation = this.currentPDFRotation !== null ? this.currentPDFRotation + 90 : 90;
  }

  zoomPDF(zoomBy: number, sign: string): void {
    if (sign === '+' && this.currentPDFZoom <= 3.01) {
      this.currentPDFZoom = this.currentPDFZoom !== null ? this.currentPDFZoom + zoomBy : 1.1;
    }
    if (sign === '-' && this.currentPDFZoom >= 0.31) {
      this.currentPDFZoom = this.currentPDFZoom !== null ? this.currentPDFZoom - zoomBy : 0.9;
    }
  }

  searchSubStrInCurrentPDF(stringToSearch: string): void {
    this.pdfComponent.pdfFindController.executeCommand('find', {
      query: stringToSearch,
      type: 'again',
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true
    });
  }


  toggleLeftNav(): void {
    this.isLeftNavClosed = !this.isLeftNavClosed;
    setTimeout(() => {this.pdfComponent.updateSize(); });

  }
}
