import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as lodash from 'lodash';

@Injectable()
export class AppDataFetchService {

  constructor(private http: HttpClient) {
  }

  baseUrl = '/api';

  filesList(params: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/files-list');
  }

  createNewFolder(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/new-folder', body);
  }

  deleteFileFolder(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/delete-file-folder', body);
  }

  renameFileFolder(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/rename-file-folder', body);
  }

  loadS3PDF(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/file-load', body);
  }

  chatWithPDF(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/chat-with-pdf', body);
  }

  loadChatPDF(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/load-chat-pdf', body);
  }

  deleteOneMessage(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/delete-one-message', body);
  }

  // Internal
  fileUpload(file: any, param: any): Observable<any> {
    const formData = new FormData();
    formData.append('params', JSON.stringify(param));
    formData.append('file', file);
    return this.http.post(this.baseUrl + '/file-upload', formData, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    });
  }

  semanticSearch(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/semantic-search', body);
  }

  semanticSearchFeedBack(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/semantic-search-feedback', body);
  }

  // Analytics
  analyticsPermission(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/analytics/permission');
  }

  loadUsages(queryParam): string {
    return (this.baseUrl + '/analytics' + (queryParam ? queryParam : ''));
  }

  viewPDF(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/analytics/view-pdf', body);
  }

}
