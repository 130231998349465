import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})
export class AppsettingsService {
  private http: HttpClient;
  configSettings: any = null;

  constructor(http: HttpClient) {
    this.http = http;
  }

  get settings(): any {
    return this.configSettings;
  }

  load(): Promise<any> {
    return this.http.get<any>('/app-settings').toPromise()
      .then(config => {
        this.configSettings = config;
      });
  }
}
