import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-rename-dlg',
  templateUrl: './rename-dlg.component.html',
  styleUrls: ['./rename-dlg.component.less']
})
export class RenameDlgComponent implements OnInit {
  @Input() bodyText: string;
  @Input() inputConfirmText: string;
  @Input() errorMsgHtml: string;
  @Output() proceedStatus: EventEmitter<any> = new EventEmitter();

  fg!: FormGroup;

  constructor(
    private fb: FormBuilder, public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.fg = this.fb.group({
      newName: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  onSubmit(): void {
    this.errorMsgHtml = null;
    if (this.fg.controls.newName.status !== 'INVALID'
      && this.fg.controls.newName.value) {
      this.proceedStatus.emit(this.fg.controls.newName.value);
    } else {
      this.errorMsgHtml = `Invalid Input`;
    }
  }


}
