import {Component, OnInit} from '@angular/core';
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from '@angular/common';
import {ServerDataSource, DefaultEditor, ViewCell} from 'ng2-smart-table';
import {HttpClient} from '@angular/common/http';

import {ButtonViewComponent} from '../common/st-buttonview/button-view.component';

@Component({
  selector: 'app-usages',
  templateUrl: './usages.component.html',
  styleUrls: ['./usages.component.less']
})
export class UsagesComponent implements OnInit {
  errorMsgHtml;
  onlyClientsUsages = true;
  source: ServerDataSource;
  gridSettings = {
    actions: {add: false, edit: false, delete: false},
    columns: {
      ActionPlaceholder: {
        title: '',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.buttonUi = {
            showPDF: true,
            rowActionBtnClass: 'btn btn-light'
          };
          instance.openPDF.subscribe(row => {
            this.openPDF(row);
          });
        },
        filter: false
      },
      bvresourcesFileKey: {
        title: 'File Title'
      },
      query: {
        title: 'Query'
      },
      response: {
        title: 'Answer',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.buttonUi = {
            showInnerHTML: true
          };
        },
      },
      email: {
        title: 'User'
      },
      datetime: {
        title: 'Time',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          const raw = new Date(data);
          return this.datePipe.transform(raw, 'M/dd/yy HH:mm:ss');
        },
        filter: false,
      }
    },
    attr: {
      class: 'table table-st table-responsive'
    },
    pager: {
      display: true,
      perPage: 10
    },
    noDataMessage: ''
  };

  constructor(
    private datePipe: DatePipe,
    private appDataFetchService: AppDataFetchService,
    private modalService: NgbModal,
    private http: HttpClient) {
  }


  ngOnInit(): void {
    this.loadUsages();
  }


  private loadUsages(): void {
    this.appDataFetchService.analyticsPermission().subscribe({
      next: () => {
        this.initializeDataSource();
      },
      error: (error) => {
        this.errorMsgHtml = `An error occurred: ${error.message || error}`;
        console.error('Error loading usages:', error);
      }
    });
  }

  private initializeDataSource(): void {
    this.source = new ServerDataSource(this.http, {
      endPoint: this.appDataFetchService.loadUsages(this.onlyClientsUsages ? `?only_clients_usages=${this.onlyClientsUsages}` : null),
      dataKey: 'result',
      pagerPageKey: 'currentPageNumber',
      pagerLimitKey: 'totalItemsCountPerPage',
      totalKey: 'totalItemsCount'
    });

    this.source.onChanged().subscribe();
  }


  private openPDF(row): void {
    this.appDataFetchService.viewPDF(row).subscribe((result) => {
      if (result && result.url) {
        window.open(result.url, '_blank');
      }
    }, (error) => {
      this.errorMsgHtml = error;
      console.error(error);
    });
  }

  onlyClientsUsagesChecked($event: any): void {
    this.loadUsages();
  }
}
