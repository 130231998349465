<div>
  Customers with subscriptions and pid
  <p><a href="main"> Customer RADA</a></p>
</div>

<div>
  Inhouse Users and Only Non-Production Env
  <p><a href="main?custom_upload"> Custom uploads</a></p>
</div>

<div>
  Inhouse Users and all Env
  <p><a href="search"> Semantic Search </a></p>
</div>

<div>
  Only Specified Inhouse Users And VPN
  <p><a href="usages"> USAGES</a></p>
</div>



