<div class="card-main-wrapper mb-4">
  <div>
    <div class="card card-custom">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">
        <div class="card-title">
          RADA Usages
        </div>
        <div class="card-toolbar">
          <div>
            <label>
              <input type="checkbox" [(ngModel)]="onlyClientsUsages" (change)="onlyClientsUsagesChecked($event)" /> Only Client Usages
            </label>
          </div>

        </div>
      </div>
      <div class="card-body p-0">
        <div>
          <div class="error-msg-container alert-danger" *ngIf="errorMsgHtml">{{errorMsgHtml}}</div>
          <ng2-smart-table [settings]="gridSettings" [source]="source"></ng2-smart-table>
        </div>
      </div>

    </div>
  </div>
</div>
